.button {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: var(--spacing-inline-md);
  font-size: 1rem;
  border-radius: var(--border-radius);
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: var(--color-blue);
  box-shadow: var(--elevation-1);
}
.button.transparent {
  background: transparent;
  color: black;
  border: inherit;
  box-shadow: none;
}
.button:focus {
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: var(--box-shadow-focus);
}
.button.disabled,
.button:disabled {
  opacity: 0.58;
  pointer-events: none;
  box-shadow: none;
}
