@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap);
:root {
  /* colors */
  --color-text-muted: #666666;
  --color-neutral-dark: #1f1f1f;
  --color-blue: #0091ffaa;
  --color-lightest-blue: #f4f8ff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #f8f9fa;
  --color-dark: #212529;
  --color-muted-blue: rgb(4 3 105 / 38%);

  --box-shadow-focus: 0 0 0 0.25rem var(--color-muted-blue);
  --border-radius: 0.35rem;

  /* spacing */
  --spacing-stack-sm: 0.5rem 0;
  --spacing-stack-md: 0.65rem 0;
  --spacing-stack-top-sm: 0.5rem 0 0 0;
  --spacing-stack-top-md: 0.65rem 0 0 0;
  --spacing-stack-top-lg: 0.75rem 0 0 0;
  --spacing-inset-sm: 0.5rem;
  --spacing-inset-md: 0.65rem;
  --spacing-inset-lg: 0.75rem;
  --spacing-inline-sm: 0 0.5rem 0 0;
  --spacing-inline-md: 0 0.65rem 0 0;
  --spacing-inline-lg: 0 0.75rem 0 0;

  /* elevations */
  /* buttons */
  --elevation-1: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  /* card, hotspot */
  --elevation-2: 0 0 1px 0 rgba(0, 0, 0, 0.16),
    0 4px 8px -2px rgba(0, 0, 0, 0.24);

  /* type */
  --header-font-size-1: 2.125rem;
  --header-font-size-2: 1.75rem;
  --header-line-height-1: 2.75rem;
  --header-line-height-2: 2.25rem;
  --header-font-weight-1: 500;
  --header-font-weight-2: 700;

  --body-font-size-1: 1.125rem;
  --body-font-size-2: 1rem;
  --body-line-height-1: 1.75rem;
  --body-line-height-2: 1.5rem;
  --body-font-weight-1: 600;
  --body-font-weight-2: 500;
  --body-font-weight-3: 400;
}

* {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}
body,
html {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f4f8ff;
  background-color: var(--color-lightest-blue);
  height: 100vh;
}

#root {
  height: 100%;
}
.App {
  max-width: 992px;
  margin: 0 auto;
  padding: 1em;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Util Classes */

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-inline-center {
  display: inline-flex;
  align-items: center;
}
.m-stack-md {
  margin: 0.65rem 0 !important;
  margin: var(--spacing-stack-md) !important;
}
.m-inline-md {
  margin: 0 0.65rem 0 0 !important;
  margin: var(--spacing-inline-md) !important;
}
.m-stack-top-lg {
  margin: 0.75rem 0 0 0 !important;
  margin: var(--spacing-stack-top-lg) !important;
}

.w-100 {
  width: 100%;
}

.card {
  background: #ffffff;
  background: var(--color-white);
  padding: 0.75rem;
  padding: var(--spacing-inset-lg);
  border: 1px solid #666666;
  border: 1px solid var(--color-text-muted);
  border-radius: 0.35rem;
  border-radius: var(--border-radius);
}

.p1 {
  font-size: 1.125rem;
  font-size: var(--body-font-size-1);
  font-weight: 400;
  font-weight: var(--body-font-weight-3);
}

.button {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: var(--spacing-inline-md);
  font-size: 1rem;
  border-radius: var(--border-radius);
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: var(--color-blue);
  box-shadow: var(--elevation-1);
}
.button.transparent {
  background: transparent;
  color: black;
  border: inherit;
  box-shadow: none;
}
.button:focus {
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: var(--box-shadow-focus);
}
.button.disabled,
.button:disabled {
  opacity: 0.58;
  pointer-events: none;
  box-shadow: none;
}

.input {
  display: inline-block;
  width: inherit;
  padding: var(--spacing-inset-sm);
  margin: var(--spacing-inline-md);
  font-size: 1rem;
  color: var(--color-text-muted);
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-dark);
  border-radius: var(--border-radius);
  transition: all 0.2s ease-in-out;
  flex-grow: 1;
  flex-basis: 0;
}
.input:focus {
  color: #212529;
  background-color: var(--color-white);
  border-color: var(--color-blue);
  outline: 0;
  box-shadow: var(--box-shadow-focus);
}
.label {
  font-size: var(--body-font-size-1);
  font-weight: var(--body-font-weight-1);
  line-height: var(--body-line-height-1);
}

